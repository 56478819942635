import "./App.css"
import Embed from "./Embed"
import { ThemeProvider } from "@mui/material"
import GlobalTheme from "./GlobalTheme"
import { useEffect, useRef, useState } from "react"
import { logger } from "./utils"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ErrorPage } from "./ErrorPage"
import { Layout } from "./LayoutTab"
import { EDITOR_WIDTH_PX } from "./constants"
import { config } from "./utils"
import { relinquishDocumentEdit } from "./api_utils"
import { Events, initTracker, tracker } from "./mixpanel"

const SDK = require("./blocksdk")

function App() {
  const sdk = useRef<any>()
  const workspaceRef = useRef<any>(undefined)
  const [loadingSdk, setLoadingSdk] = useState(true)

  useEffect(() => {
    if (sdk.current === undefined) {
      logger.debug("creating sdk instance.")
      sdk.current = new SDK({
        blockEditorWidth: EDITOR_WIDTH_PX,
        onEditClose: doneEditing,
        tabs: [{ key: "layout", url: `${window.location.origin}/layout`, name: "Layout" }, "htmlblock", "stylingblock"],
      })
      sdk.current?.getUserData((user: any) => {
        initTracker(user)
      })
      setLoadingSdk(false)
      tracker.track(Events.LOAD_BLOCK)
    }
  }, [loadingSdk])

  const doneEditing = () => {
    logger.debug("Done editing called", workspaceRef)
    let docRefCurrent = workspaceRef.current
    if (docRefCurrent && docRefCurrent.documentId && docRefCurrent.workspaceId && docRefCurrent.authToken) {
      logger.debug("going to use env urls for relinq", config, "with stored data", docRefCurrent)
      // Release document lock
      relinquishDocumentEdit(
        docRefCurrent.typefaceEndpoint || config.apiBase,
        docRefCurrent.workspaceId,
        docRefCurrent.documentId,
        docRefCurrent.authToken,
      ).then((response) => {
        logger.debug(response)
      })
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Embed workspaceRef={workspaceRef} blockSdkRef={sdk} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/layout",
      element: <Layout blocksdkRef={sdk} />,
    },
  ])

  return loadingSdk ? (
    <h1>intializing sdk...</h1>
  ) : (
    <ThemeProvider theme={GlobalTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
