import Lottie from "lottie-react"
import loadingAnimation from "./typeface-loader-lottie.json"

interface TypeFaceLoadingProps {
  width?: number
  height?: number
  onClick?: any
}

const defaultProps = {
  width: 40,
  height: 40,
}

const TypeFaceLoading = (props: TypeFaceLoadingProps) => {
  const { width, height, onClick } = { ...props }

  const noOp = () => {}

  return (
    <div onClick={onClick || noOp} style={{ width: width, height: height }}>
      <Lottie
        animationData={loadingAnimation}
        loop={true}
        autoplay={true}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
        height={width}
        width={height}
      />
    </div>
  )
}

TypeFaceLoading.defaultProps = defaultProps

export default TypeFaceLoading
