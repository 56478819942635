import { Button, ButtonProps, Tooltip, styled } from "@mui/material"
import { CenterAlign, LeftAlign, RightAlign } from "../icons/common"

export type ImageAlign = "left" | "right" | "center"

interface AlignmentButtonProps {
  className?: string
  onClick: (s: ImageAlign) => void
  type: ImageAlign
  toolTip?: string
}

const StyledAlignment = styled(Button)<ButtonProps>(({ theme, className }) => ({
  borderRadius: "0px",
  height: "37.13px",
  "&.MuiButton-outlinedPrimary": {
    border: className === "align-button-selected" ? "2px solid black" : "1px solid #11101366",
  },
}))

const AlignmentButton = ({ className, onClick, type, toolTip }: AlignmentButtonProps) => {
  const button = (
    <StyledAlignment className={className} fullWidth={true} variant="outlined" onClick={() => onClick(type)}>
      {type === "left" ? <LeftAlign /> : type === "right" ? <RightAlign /> : <CenterAlign />}
    </StyledAlignment>
  )
  return toolTip ? <Tooltip title={toolTip}>{button}</Tooltip> : button
}

export default AlignmentButton
