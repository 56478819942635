import { logger } from "./utils"

const fetchConfig = (token: string, method?: string | "GET") => {
  return {
    method: method,
    headers: {
      Authorization: token,
    },
  }
}

export const relinquishDocumentEdit = async (host: string, workspaceId: number, documentId: number, token: string) => {
  let response: any = {}
  await fetch(
    host + `/content-service/workspace/${workspaceId}/documents/${documentId}/edit`,
    fetchConfig(token, "DELETE"),
  )
    .then((res: any) => {
      response = res
    })
    .catch((err) => {
      logger.debug("couldn't relinquish", err)
    })
  return response
}
