import { LogLevel } from "./Logger"

export const BETA_URL = "https://sfdc-cb-beta.typeface.ai"
export const PROD_URL = "https://sfdc-cb-prod.typeface.ai"

export interface AppConfig {
  webApp: string
  apiBase: string
  logLevel: LogLevel
}

export const local: AppConfig = {
  webApp: "http://localhost:3000",
  apiBase: "https://api-dev-eu2-su1.typeface.ai",
  logLevel: LogLevel.DEBUG,
}

export const beta: AppConfig = {
  webApp: "https://beta.typeface.ai",
  apiBase: "https://api-dev-eu2-su1.typeface.ai",
  logLevel: LogLevel.DEBUG,
}

export const prod: AppConfig = {
  webApp: "https://app.typeface.ai",
  apiBase: "https://api-us.typeface.ai",
  logLevel: LogLevel.INFO,
}

export const preview: AppConfig = {
  webApp: "https://kind-pond-08f7fc10f-embed.eastus2.3.azurestaticapps.net",
  apiBase: "https://api-dev-eu2-su1.typeface.ai",
  logLevel: LogLevel.DEBUG,
}

export enum ENVS {
  LOCAL = "local",
  BETA = "beta",
  PREVIEW = "preview",
  PROD = "prod",
}
