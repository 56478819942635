import { DOMNode, Element } from "html-react-parser"
import { ConsoleLogger, LogLevel } from "./Logger"
import { ENVS, beta, local, preview, prod } from "./config"

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

/**
 * Used to determine if a parsed domNode is of type `Element`.
 * @param domNode parsed node
 * @returns true if parsed node is of type Element
 */
export const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === "tag"
  const hasAttributes = (domNode as Element).attribs !== undefined

  return isTag && hasAttributes
}

export const downloadBase64ImageFromUrl = async (url: any) => {
  const response = await fetch(url)
  const blob = await response.blob()
  let base64Image = await blobToBase64(blob)
  return base64Image
}

export const setHtmlFromContentData = (contentData: any, setContent: any) => {
  // Add directly
  setContent(`<img src="${contentData}" />`)
  //
}

export const getErrorMessage = (editedBy: string | undefined) => {
  return `This block is currently being edited by ${
    editedBy ? editedBy : "some other user"
  }. You can access this block after they have finished editing`
}

export const getEnvEnum = (en: string | undefined) => {
  if (en === undefined) {
    const env = getEnvFromHost(window.location.toString())
    console.log("Found env var undefined or null. Falling back to computing env from host. Detected env ", env)
    return env
  }
  switch (en) {
    case "local":
      return ENVS.LOCAL
    case "beta":
      return ENVS.BETA
    case "preview":
      return ENVS.PREVIEW
    case "prod":
      return ENVS.PROD
    default:
      return ENVS.LOCAL
  }
}

export const getConfig = (en: ENVS) => {
  switch (en) {
    case ENVS.LOCAL:
      return local
    case ENVS.BETA:
      return beta
    case ENVS.PREVIEW:
      return preview
    case ENVS.PROD:
      return prod
  }
}

export const getEnvFromHost = (hostname: string) => {
  if (hostname.startsWith("https://sfdc-cb-beta.typeface.ai")) {
    return ENVS.BETA
  } else if (hostname.startsWith("https://sfdc-cb-prod.typeface.ai")) {
    return ENVS.PROD
  }
  return ENVS.LOCAL
}

export const computeConfig = (selectedEnv: string | undefined) => {
  const config = getConfig(getEnvEnum(selectedEnv))
  console.debug("got urls for env ", selectedEnv, config)
  return config
}

export const config = computeConfig(process.env.REACT_APP_ENV)

export const logger = new ConsoleLogger({ level: config.logLevel ?? LogLevel.LOG })

/**
 * Returns image dimensions for specified URL.
 */
export const getImageDimensions = (url: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
      })
    img.onerror = (error) => reject(error)
    img.src = url
  })
}

export const getHeightForAspectRatio = (ar: number, width: number) => {
  if (ar === 0) throw Error("Aspect Ratio can't be zero!")
  return Math.floor(width / ar)
}

export const getWidthForAspectRatio = (ar: number, height: number) => {
  return Math.floor(height * ar)
}
