export enum SalesforceUsecases {
  IMAGE_BLOCK = "imageBlock",
}

const useCasePrefix = "SalesforceMarketingCloud"

export const getUseCase = (useCase: SalesforceUsecases) => {
  return [useCasePrefix, useCase.toString()].join("_")
}

export const SOURCE_APP = "salesforce"

export const EDITOR_WIDTH_PX = 800

export const IMAGE_WIDTH = 768
export const IMAGE_HEIGHT = 576

export const INTERVAL_LOAD_MILLIS = 5000
export const loadingMessages = [
  "Uploading to Content Builder...",
  "Uploaded. Fetching URL...",
  "Waiting for CDN propagation. This could take upto a minute...",
]
