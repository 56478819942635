/* eslint-disable no-console */
export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

export enum LogLevel {
    ERROR = "error",
    WARN = "warn",
    DEBUG = "debug",
    INFO = "info",
    LOG = "log"
}

/** Basic logger interface */
export interface Logger {
    log: LogFn;
    info: LogFn;
    debug: LogFn;
    warn: LogFn;
    error: LogFn;
}

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => { };

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    readonly log: LogFn;
    readonly info: LogFn;
    readonly debug: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;

    constructor(options?: { level?: LogLevel }) {
        const { level } = options || {};

        this.error = console.error.bind(console);
        if (level === 'error') {
            this.warn = NO_OP;
            this.info = NO_OP;
            this.log = NO_OP;
            this.debug = NO_OP;
            return;
        }

        this.warn = console.warn.bind(console);
        if (level === 'warn') {
            this.info = NO_OP;
            this.log = NO_OP;
            this.debug = NO_OP;
            return;
        }

        this.info = console.info.bind(console);
        if (level === 'info') {
            this.log = NO_OP;
            this.debug = NO_OP;
            return;
        }

        this.log = console.log.bind(console);
        if (level === 'log') {
            this.debug = NO_OP;
            return;
        }
        this.debug = console.debug.bind(console);
    }
}