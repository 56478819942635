import { useEffect, useRef, useState } from "react"
import parse, { HTMLReactParserOptions, attributesToProps } from "html-react-parser"
import { getHeightForAspectRatio, getImageDimensions, getWidthForAspectRatio, isElement, logger } from "./utils"
import { renderToString } from "react-dom/server"
import { Box, Grid, IconButton, InputLabel, Stack } from "@mui/material"
import { Lock, LockOpen } from "./icons/common"
import { Question } from "./icons/common"
import "./LayoutTab.css"
import { ImageDim } from "./components/ImageDimInput"
import ImageDimInput from "./components/ImageDimInput"
import { ImageAlign } from "./components/AlignmentButton"
import AlignmentButton from "./components/AlignmentButton"
import TfTextField from "./components/TfTextField"
import TfButton from "./components/TfButton"
import AutoFixHigh from "@mui/icons-material/AutoFixHigh"
import { Events, tracker } from "./mixpanel"
import { BlockType } from "@typeface-ai/typeface-embed-react/lib/esm/definitions"

// we need to get these
// 1. alt text
// 2. link href
// 3. img width
// 4. img height
// 5. alignment
// 6. AR locked?

interface BuildBlockContentProps {
  imageSrc: string
  altText?: string
  linkHref?: string
  imageWidth?: number
  imageHeight?: number
  alignment?: ImageAlign
  arLocked?: boolean
}

interface ImageStyles {
  objectFit?: "contain" | "fill"
  height?: string
  width?: string
}

const buildBlockContent = (params: BuildBlockContentProps) => {
  const imageStyles: ImageStyles = {
    objectFit: params.arLocked ? "contain" : "fill",
  }
  if (params.imageHeight !== undefined) {
    imageStyles.height = `${params.imageHeight}px`
  }
  if (params.imageWidth !== undefined) {
    imageStyles.width = `${params.imageWidth}px`
  }
  const imgWithStyles = <img src={params.imageSrc} style={imageStyles} alt={params.altText} />
  const toSet = (
    <div id="tf-align" style={{ textAlign: params.alignment ? params.alignment : "left" }}>
      {params.linkHref ? (
        <a id="tf-link" href={params.linkHref} target="_blank" rel="noopener noreferrer">
          {imgWithStyles}
        </a>
      ) : (
        imgWithStyles
      )}{" "}
    </div>
  )
  return toSet
}

export const Layout = (props: { blocksdkRef: any }) => {
  const [content, setContent] = useState("")
  const sdkRef = props.blocksdkRef
  const [imgAlign, setImgAlign] = useState<ImageAlign>()
  const [imgWidth, setImageWidth] = useState<number>()
  const [imgHeight, setImageHeight] = useState<number>()
  const [sdkContent, setSdkContent] = useState<JSX.Element>()
  const [altText, setAltText] = useState<string>()
  const [link, setLink] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [imgSrc, setImgSrc] = useState("")
  const [lockAspectRatio, setLockAR] = useState(true)
  let linkDef = "default"
  const [computedAspectRatio, setComputedAspectRatio] = useState<number>()
  const documentRef = useRef({
    documentId: undefined,
    blockId: undefined,
    accountId: undefined,
    workspaceId: undefined,
    typefaceEndpoint: undefined,
    blockType: undefined,
  })

  useEffect(() => {
    if (sdkRef.current !== undefined) {
      if (loading) {
        sdkRef.current.getContent((c: string) => {
          logger.debug("set initial content length", c.length)
          setContent(c)
          intializeState(c)
          setLoading(false)
          tracker.track(Events.LAYOUT_PAGE_LOAD)
        })

        sdkRef.current.getData((data: any) => {
          logger.debug("init data", data)
          documentRef.current = {
            documentId: data?.documentId,
            blockId: data?.blockId,
            accountId: data?.accountId,
            workspaceId: data?.workspaceId,
            typefaceEndpoint: data?.typefaceEndpoint,
            blockType: data?.blockType,
          }
          setLoading(false)
        })
      }
    } else {
      logger.info("sdk ref undefined!")
    }
  })

  useEffect(() => {
    if (content === "") return
    const toSet = buildBlockContent({
      imageSrc: imgSrc,
      altText: altText,
      imageWidth: imgWidth,
      imageHeight: imgHeight,
      linkHref: link,
      alignment: imgAlign,
      arLocked: lockAspectRatio,
    })
    setSdkContent(toSet)

    // update if no change comes in next 'x' seconds
    const delayDebounce = setTimeout(() => {
      logger.debug("refreshing content")
      if (sdkRef.current !== undefined) {
        if (documentRef?.current?.blockType !== BlockType.Text) sdkRef.current.setContent(renderToString(toSet))
      }
    }, 1000)

    return () => clearTimeout(delayDebounce)
  }, [content, altText, imgWidth, imgHeight, imgAlign, link, sdkRef, imgSrc, lockAspectRatio])

  useEffect(() => {
    if (!imgSrc || computedAspectRatio) {
      return
    }
    getImageDimensions(imgSrc)
      .then((dims) => {
        logger.debug(dims, "dimensions")
        if (!imgHeight) {
          setImageHeight(dims.height)
        }
        if (!imgWidth) {
          setImageWidth(dims.width)
        }
        if (dims.height !== 0) {
          setComputedAspectRatio(dims.width / dims.height)
        }
      })
      .catch((err) => {
        logger.error("couldn't compute dims", err)
      })
  }, [imgSrc, imgHeight, imgWidth, computedAspectRatio])

  const intializeState = (content: string | undefined) => {
    if (content === undefined || content === "") {
      logger.debug("empty content. not setting state")
      return
    }
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (!isElement(domNode)) {
          return
        }
        if (isElement(domNode) && domNode.attribs && domNode.name === "img") {
          const elProps = attributesToProps(domNode.attribs)
          if (elProps.alt !== undefined) {
            setAltText(elProps.alt)
          }
          if (elProps.src !== undefined) {
            setImgSrc(elProps.src)
          }
          if (elProps.style !== undefined) {
            if (elProps.style.height !== undefined) {
              const height = elProps.style.height.replaceAll("px", "")
              logger.debug(height, "detected height")
              setImageHeight(Number(height))
            }
            if (elProps.style.width !== undefined) {
              const width = elProps.style.width.replaceAll("px", "")
              setImageWidth(Number(width))
            }
            if (elProps.style.objectFit !== undefined) {
              setLockAR(elProps.style.objectFit === "contain")
            }
          }
        } else if (domNode.attribs && domNode.attribs.id === "tf-align") {
          const elProps = attributesToProps(domNode.attribs)
          if (elProps.style !== undefined && elProps.style.textAlign !== undefined) {
            const val = elProps.style.textAlign
            if (val === "right") {
              setImgAlign("right")
            } else if (val === "center") {
              setImgAlign("center")
            }
          }
        } else if (domNode.attribs && domNode.attribs.id === "tf-link") {
          logger.debug(domNode, "link node")
          const elProps = attributesToProps(domNode.attribs)
          if (elProps.href !== undefined) {
            setLink(elProps.href)
            linkDef = elProps.href
            logger.debug(linkDef, "link def")
          }
        }
      },
    }
    parse(content, options)
  }

  const alignButtonClass = (buttonType: ImageAlign) => {
    if (imgAlign === buttonType) {
      return "align-button-selected"
    } else {
      return "align-button"
    }
  }

  const alignmentHandle = (alignTo: ImageAlign) => {
    setImgAlign(alignTo)
  }

  const setImageDims = (prop: ImageDim, value: number) => {
    if (prop === "height") {
      setImageHeight(value)
      if (lockAspectRatio && computedAspectRatio) {
        setImageWidth(getWidthForAspectRatio(computedAspectRatio, value))
      }
    } else if (prop === "width") {
      setImageWidth(value)
      if (lockAspectRatio && computedAspectRatio) {
        setImageHeight(getHeightForAspectRatio(computedAspectRatio, value))
      }
    }
  }

  const questionTooltip = (tooltip: string) => {
    return <Question className="question-icon" toolTip={tooltip} />
  }

  if (documentRef?.current?.blockType === BlockType.Text) return <></>

  return loading ? (
    <h1>loading</h1>
  ) : (
    <>
      <Box>
        <Box className="layout-tab-container">
          <Grid className="image-dims-align-grid" container spacing={2}>
            <Grid className="image-dims-align-grid-item" item xs={6}>
              <InputLabel className="field-label">Dimensions</InputLabel>
              <Stack direction={"row"}>
                <ImageDimInput type="width" value={String(imgWidth)} setValue={setImageDims} />
                <ImageDimInput type="height" value={String(imgHeight)} setValue={setImageDims} />
                <IconButton
                  className="lock-icon-button"
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.10)",
                    height: "fit-content",
                    padding: "4px",
                    margin: "auto",
                    borderRadius: "4px",
                  }}
                  onClick={() => setLockAR(!lockAspectRatio)}
                >
                  {lockAspectRatio === true ? (
                    <Lock className="lock-icon" toolTip="Aspect ratio locked" />
                  ) : (
                    <LockOpen className="lock-icon" toolTip="Aspect ratio not locked" />
                  )}
                </IconButton>
              </Stack>
            </Grid>
            <Grid
              className="image-dims-align-grid-item"
              item
              xs={6}
              sx={{
                "&.MuiGrid-item": {
                  paddingTop: "11px",
                },
              }}
            >
              <InputLabel className="field-label">Image Alignment{questionTooltip("Align the image")}</InputLabel>
              <Grid container spacing={0.5}>
                <Grid item xs={4}>
                  <AlignmentButton
                    type="left"
                    className={alignButtonClass("left")}
                    onClick={alignmentHandle}
                    toolTip="Left Align"
                  />
                </Grid>
                <Grid item xs={4}>
                  <AlignmentButton
                    type="center"
                    className={alignButtonClass("center")}
                    onClick={alignmentHandle}
                    toolTip="Center Align"
                  />
                </Grid>
                <Grid item xs={4}>
                  <AlignmentButton
                    type="right"
                    className={alignButtonClass("right")}
                    onClick={alignmentHandle}
                    toolTip="Right Align"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className="alt-text-container">
            <InputLabel className="field-label">
              Alt text{questionTooltip("Add alternative text in case the image doesn't render")}
            </InputLabel>
            <TfTextField
              className="tf-textfield"
              value={altText}
              multiline
              fullWidth={true}
              placeholder="Description of the image"
              onChange={(e) => {
                setAltText(e.target.value)
              }}
            />
          </Box>
          <Box className="link-container">
            <InputLabel className="field-label">Image Link{questionTooltip("Wrap the image with a link")}</InputLabel>
            <TfTextField
              fullWidth={true}
              placeholder="Paste link"
              value={link}
              onChange={(e) => {
                setLink(e.target.value)
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
