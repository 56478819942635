import { SvgIcon, SxProps, Theme, Tooltip } from "@mui/material"

interface IconProps {
  sx?: SxProps<Theme>
  className?: string
  toolTip?: string
  color?: string
}

export const LeftAlign = ({ sx }: IconProps) => {
  return (
    <SvgIcon sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M5 7V25C5 25.2652 4.89464 25.5196 4.70711 25.7071C4.51957 25.8946 4.26522 26 4 26C3.73478 26 3.48043 25.8946 3.29289 25.7071C3.10536 25.5196 3 25.2652 3 25V7C3 6.73478 3.10536 6.48043 3.29289 6.29289C3.48043 6.10536 3.73478 6 4 6C4.26522 6 4.51957 6.10536 4.70711 6.29289C4.89464 6.48043 5 6.73478 5 7ZM30 12V20C30 20.5304 29.7893 21.0391 29.4142 21.4142C29.0391 21.7893 28.5304 22 28 22H9C8.46957 22 7.96086 21.7893 7.58579 21.4142C7.21071 21.0391 7 20.5304 7 20V12C7 11.4696 7.21071 10.9609 7.58579 10.5858C7.96086 10.2107 8.46957 10 9 10H28C28.5304 10 29.0391 10.2107 29.4142 10.5858C29.7893 10.9609 30 11.4696 30 12ZM28 20V12H9V20H28Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  )
}

export const RightAlign = ({ sx }: IconProps) => {
  return (
    <SvgIcon sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M29 7V25C29 25.2652 28.8946 25.5196 28.7071 25.7071C28.5196 25.8946 28.2652 26 28 26C27.7348 26 27.4804 25.8946 27.2929 25.7071C27.1054 25.5196 27 25.2652 27 25V7C27 6.73478 27.1054 6.48043 27.2929 6.29289C27.4804 6.10536 27.7348 6 28 6C28.2652 6 28.5196 6.10536 28.7071 6.29289C28.8946 6.48043 29 6.73478 29 7ZM25 12V20C25 20.5304 24.7893 21.0391 24.4142 21.4142C24.0391 21.7893 23.5304 22 23 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V12C2 11.4696 2.21071 10.9609 2.58579 10.5858C2.96086 10.2107 3.46957 10 4 10H23C23.5304 10 24.0391 10.2107 24.4142 10.5858C24.7893 10.9609 25 11.4696 25 12ZM23 12H4V20H23V12Z"
          fill="#111013"
        />
      </svg>
    </SvgIcon>
  )
}

export const CenterAlign = ({ sx }: IconProps) => {
  return (
    <SvgIcon sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M26 15H22V6C22 5.46957 21.7893 4.96086 21.4142 4.58579C21.0391 4.21071 20.5304 4 20 4H12C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6V15H6C5.73478 15 5.48043 15.1054 5.29289 15.2929C5.10536 15.4804 5 15.7348 5 16C5 16.2652 5.10536 16.5196 5.29289 16.7071C5.48043 16.8946 5.73478 17 6 17H10V26C10 26.5304 10.2107 27.0391 10.5858 27.4142C10.9609 27.7893 11.4696 28 12 28H20C20.5304 28 21.0391 27.7893 21.4142 27.4142C21.7893 27.0391 22 26.5304 22 26V17H26C26.2652 17 26.5196 16.8946 26.7071 16.7071C26.8946 16.5196 27 16.2652 27 16C27 15.7348 26.8946 15.4804 26.7071 15.2929C26.5196 15.1054 26.2652 15 26 15ZM20 26H12V6H20V26Z"
          fill="#111013"
        />
      </svg>
    </SvgIcon>
  )
}

export const Question = ({ className, sx, toolTip }: IconProps) => {
  const icon = (
    <SvgIcon className={className} sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          d="M6 10.9999H7.33333V9.66658H6V10.9999ZM6.66667 0.333252C5.79119 0.333252 4.92428 0.50569 4.11544 0.840722C3.30661 1.17575 2.57168 1.66682 1.95262 2.28587C0.702379 3.53612 0 5.23181 0 6.99992C0 8.76803 0.702379 10.4637 1.95262 11.714C2.57168 12.333 3.30661 12.8241 4.11544 13.1591C4.92428 13.4941 5.79119 13.6666 6.66667 13.6666C8.43478 13.6666 10.1305 12.9642 11.3807 11.714C12.631 10.4637 13.3333 8.76803 13.3333 6.99992C13.3333 6.12444 13.1609 5.25753 12.8259 4.4487C12.4908 3.63986 11.9998 2.90493 11.3807 2.28587C10.7617 1.66682 10.0267 1.17575 9.21789 0.840722C8.40905 0.50569 7.54215 0.333252 6.66667 0.333252ZM6.66667 12.3333C3.72667 12.3333 1.33333 9.93992 1.33333 6.99992C1.33333 4.05992 3.72667 1.66659 6.66667 1.66659C9.60667 1.66659 12 4.05992 12 6.99992C12 9.93992 9.60667 12.3333 6.66667 12.3333ZM6.66667 2.99992C5.95942 2.99992 5.28115 3.28087 4.78105 3.78097C4.28095 4.28106 4 4.95934 4 5.66658H5.33333C5.33333 5.31296 5.47381 4.97382 5.72386 4.72378C5.97391 4.47373 6.31304 4.33325 6.66667 4.33325C7.02029 4.33325 7.35943 4.47373 7.60948 4.72378C7.85952 4.97382 8 5.31296 8 5.66658C8 6.99992 6 6.83325 6 8.99992H7.33333C7.33333 7.49992 9.33333 7.33325 9.33333 5.66658C9.33333 4.95934 9.05238 4.28106 8.55228 3.78097C8.05219 3.28087 7.37391 2.99992 6.66667 2.99992Z"
          fill="#0A0A0A"
          fillOpacity="0.4"
        />
      </svg>
    </SvgIcon>
  )
  if (toolTip) {
    return <Tooltip title={toolTip}>{icon}</Tooltip>
  }
  return icon
}

export const Lock = ({ sx, className, toolTip }: IconProps) => {
  const icon = (
    <SvgIcon className={className} sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M13 5H11V3.5C11 2.70435 10.6839 1.94129 10.1213 1.37868C9.55871 0.81607 8.79565 0.5 8 0.5C7.20435 0.5 6.44129 0.81607 5.87868 1.37868C5.31607 1.94129 5 2.70435 5 3.5V5H3C2.73478 5 2.48043 5.10536 2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V6C14 5.73478 13.8946 5.48043 13.7071 5.29289C13.5196 5.10536 13.2652 5 13 5ZM6 3.5C6 2.96957 6.21071 2.46086 6.58579 2.08579C6.96086 1.71071 7.46957 1.5 8 1.5C8.53043 1.5 9.03914 1.71071 9.41421 2.08579C9.78929 2.46086 10 2.96957 10 3.5V5H6V3.5ZM13 13H3V6H13V13Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  )
  if (toolTip) {
    return <Tooltip title={toolTip}>{icon}</Tooltip>
  }
  return icon
}

export const LockOpen = ({ sx, className, toolTip }: IconProps) => {
  const icon = (
    <SvgIcon className={className} sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M13 5H6V3.5C6 2.96957 6.21071 2.46086 6.58579 2.08579C6.96086 1.71071 7.46957 1.5 8 1.5C8.96063 1.5 9.825 2.1875 10.01 3.09938C10.0375 3.22823 10.1147 3.34104 10.2249 3.41328C10.335 3.48551 10.4693 3.51135 10.5984 3.48516C10.7275 3.45898 10.8411 3.38289 10.9144 3.27343C10.9877 3.16397 11.0149 3.03 10.99 2.90062C10.7075 1.50937 9.45 0.5 8 0.5C7.2046 0.500827 6.44202 0.817163 5.87959 1.37959C5.31716 1.94202 5.00083 2.7046 5 3.5V5H3C2.73478 5 2.48043 5.10536 2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V6C14 5.73478 13.8946 5.48043 13.7071 5.29289C13.5196 5.10536 13.2652 5 13 5ZM13 13H3V6H13V13Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  )
  if (toolTip) {
    return <Tooltip title={toolTip}>{icon}</Tooltip>
  }
  return icon
}
