import React, { useEffect, useMemo, useRef, useState } from "react"
import "./App.css"
import {
  TypefaceEmbedIDP,
  TypefaceEmbedOutputFormat,
  TypefaceEmbedUseCase,
} from "@typeface-ai/typeface-embed-react/lib/esm/model"
import { TypefacePluginIFrame } from "@typeface-ai/typeface-embed-react/lib/esm"
import { INTERVAL_LOAD_MILLIS, SOURCE_APP, loadingMessages } from "./constants"
import { getErrorMessage, logger, setHtmlFromContentData } from "./utils"
import { config } from "./utils"
import { ErrorPage } from "./ErrorPage"
import { BlockType, OnEventMessage, TypefaceEvent } from "@typeface-ai/typeface-embed-react/lib/esm/definitions"
import { Events, tracker } from "./mixpanel"
import TypeFaceLoading from "./components/TypefaceLoading"
import { Dialog, DialogContent, Typography } from "@mui/material"
import { useInterval, useLocalStorage } from "usehooks-ts"

interface WidgetData {
  prompt?: string
  documentId?: number
  blockId?: string
  accountId?: string
  workspaceId?: number
  typefaceEndpoint?: string
  blockType?: string
}

interface AccountUser {
  id: string
  name: string
  picture: string
  email: string
}

interface EmbedProps {
  blockSdkRef: React.MutableRefObject<any>
  workspaceRef: React.MutableRefObject<any>
}

function Embed({ blockSdkRef, workspaceRef }: EmbedProps) {
  const [loading, setLoading] = useState(true)
  const documentRef = useRef({
    documentId: undefined,
    blockId: undefined,
    accountId: undefined,
    workspaceId: undefined,
    typefaceEndpoint: undefined,
    blockType: undefined,
  })
  const [showDocumentReadOnlyError, setShowDocumentReadOnlyError] = useState(false)
  const documentReadOnlyDataRef = useRef({ reason: undefined, user: undefined as AccountUser | undefined })
  const cdnLoadTimeRef = useRef({ from: new Date() })
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0)
  const [currentImageUrl, setCurrentImageUrl] = useState<string>()
  const [loadedImageUrls, setLoadedImageUrls] = useLocalStorage<string[]>("loadedImageUrls", [])
  const [waitImageLoad, setWaitImageLoad] = useState(false)

  // create interval for cdn load check
  useInterval(
    () => {
      logger.debug("running interval for cdn", loadedImageUrls)
      if (!currentImageUrl) {
        setWaitImageLoad(false)
        setLoadingMessageIndex(0)
        return
      }
      // This javascript API creates an <img> tag underneath.
      // If we use fetch, CDN will block us stating CORS.
      let img = new Image()
      img.onload = function () {
        logger.debug("image has loaded")
        setHtmlFromContentData(currentImageUrl, setContent)
        setLoadedImageUrls((prev) => [...prev, currentImageUrl])
        setCurrentImageUrl(undefined)
        setWaitImageLoad(false)
        setLoadingMessageIndex(0)
        const timeTaken = (new Date().getTime() - cdnLoadTimeRef.current.from.getTime()) / 1000
        logger.debug("took", timeTaken)
        tracker.track(Events.IMAGE_CDN_LOAD_TIME, { cdnLoadSeconds: timeTaken })
      }
      img.onerror = function () {
        logger.debug("Image hasn't loaded")
      }
      img.src = currentImageUrl
    },
    waitImageLoad ? INTERVAL_LOAD_MILLIS : null,
  )

  useInterval(
    () => {
      logger.debug("running interval for loading index")
      setLoadingMessageIndex((l) => Math.min(loadingMessages.length - 1, l + 1))
    },
    waitImageLoad ? INTERVAL_LOAD_MILLIS : null,
  )

  useEffect(() => {
    if (currentImageUrl && !loadedImageUrls.find((x) => x === currentImageUrl)) {
      setWaitImageLoad(true)
      cdnLoadTimeRef.current.from = new Date()
    } else if (currentImageUrl) {
      setHtmlFromContentData(currentImageUrl, setContent)
      setCurrentImageUrl(undefined)
      setLoadingMessageIndex(0)
      setWaitImageLoad(false)
    }
  }, [currentImageUrl, waitImageLoad, loadedImageUrls])

  // Load the component by reading the existing block content.
  useEffect(() => {
    if (loading) {
      if (blockSdkRef.current !== undefined) {
        blockSdkRef.current.getData((data: any) => {
          logger.debug("init data", data)
          documentRef.current = {
            documentId: data?.documentId,
            blockId: data?.blockId,
            accountId: data?.accountId,
            workspaceId: data?.workspaceId,
            typefaceEndpoint: data?.typefaceEndpoint,
            blockType: data?.blockType,
          }
          setLoading(false)
        })
      } else {
        logger.debug("block sdk is undefined!")
      }
    } else {
      logger.debug("loading complete!")
    }
  })

  const getParameterMap = () => {
    const params: Record<string, any> = {}

    if (documentRef.current?.documentId) {
      params.documentId = documentRef.current?.documentId
      params.workspaceId = documentRef.current?.workspaceId
      params.accountId = documentRef.current?.accountId
      params.blockType = documentRef.current?.blockType
    }
    return params
  }

  const getTypefaceOrigin = () => {
    logger.debug("going to use env urls for typeface origin", config)
    return config.webApp
  }

  const setData = (data: WidgetData) => {
    if (blockSdkRef.current === undefined) {
      logger.debug("got empty sdk ref in setData")
      return
    }
    blockSdkRef.current.setData(data)
  }

  const resetData = () => {
    documentRef.current = {
      documentId: undefined,
      blockId: undefined,
      accountId: undefined,
      workspaceId: undefined,
      typefaceEndpoint: undefined,
      blockType: undefined,
    }
    setData({ ...documentRef.current })
  }

  const setContent = (typefaceContent: string) => {
    logger.debug("set content called")
    if ("" === typefaceContent || blockSdkRef.current === undefined) {
      logger.debug("got for set content : ", typefaceContent, "with sdk: ", blockSdkRef)
      return
    }
    blockSdkRef.current.setContent(typefaceContent, function (setContent: string) {
      logger.debug(typefaceContent, "content set")
    })
  }

  const pluginProps = {
    idp: TypefaceEmbedIDP.Typeface,
    sourceApp: SOURCE_APP,
    useCase: TypefaceEmbedUseCase.Default,
    typefaceOrigin: getTypefaceOrigin(),
    parameters: getParameterMap(),
    onEvent: (event: OnEventMessage) => {
      logger.debug("received typeface event", event)
      if (event.eventType === TypefaceEvent.CANVAS_LOAD && event.data) {
        const data = event.data
        workspaceRef.current = {
          workspaceId: data.workspaceId,
          authToken: data.token,
          documentId: data.documentId,
          accountId: data.accountId,
          typefaceEndpoint: data.typefaceEndpoint,
          blockType: data.blockType,
        }
        setData({
          documentId: data.documentId,
          accountId: data.accountId,
          workspaceId: data.workspaceId,
          typefaceEndpoint: data.typefaceEndpoint,
          blockType: data.blockType,
        })
      } else if (event.eventType === TypefaceEvent.DOCUMENT_READ_ONLY) {
        setShowDocumentReadOnlyError(true)
        tracker.track(Events.DOCUMENT_READ_ONLY, { ...event.data })
        documentReadOnlyDataRef.current = {
          reason: event.data.reason,
          user: event.data.user as AccountUser,
        }
      }
    },
    onApply: (content: any) => {
      logger.debug("received", content)
      const parsed = JSON.parse(content)
      logger.debug(parsed, "parsed json on apply. setting initially")
      // setData({ documentId: parsed.documentId })
      if (parsed?.type === BlockType?.Text) {
        setContent(parsed.data.htmlString)
      } else setCurrentImageUrl(parsed.data.imageUrl)
    },
    styles: {
      height: "100vh",
      width: "100%",
      showAccountSwitcher: true,
    },
    onCancel: () => {
      logger.info("Cancel called from embed")
      resetData()
      window.location.reload()
    },
    outputFormat: TypefaceEmbedOutputFormat.JSON,
    hideHeader: false,
  }

  return loading ? (
    <h1>This website is not meant to be opened directly.</h1>
  ) : showDocumentReadOnlyError ? (
    <ErrorPage
      title="Block is not accessible."
      description={getErrorMessage(documentReadOnlyDataRef.current.user?.name)}
    />
  ) : (
    <div style={{ overflow: "hidden" }}>
      {
        <Dialog
          open={waitImageLoad}
          onClose={(o: any, reason: any) => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div className="center-content">
              <TypeFaceLoading />
            </div>
            <Typography variant="subtitle2">{loadingMessages[loadingMessageIndex]}</Typography>
          </DialogContent>
        </Dialog>
      }
      <TypefacePluginIFrame {...pluginProps} />
    </div>
  )
}

export default Embed
