import { Box, Typography } from "@mui/material"
import "./errorPage.css"

interface ErrorPageProps {
  title?: string
  description?: string
}

export const ErrorPage = ({ title, description }: ErrorPageProps) => {
  return (
    <main className="bgs-grey">
      <div className="cortex-grid cortex-grid-md">
        <Box
          component="div"
          className="error-page-box"
          sx={{
            padding: {
              xs: "50px 25px",
              sm: "100px 55px 151px 55px",
            },
          }}
        >
          <img src={`/typeface-error-logo.png`} alt="typeface-logo" style={{ marginBottom: "58px" }} />
          {/* Error title */}
          <Typography
            data-cy="error-page-title"
            sx={{ marginBottom: "23px", fontSize: { xs: "32px", sm: "43px" }, fontWeight: "600" }}
          >
            <>{title ? title : "404. Not Found"}</>
          </Typography>
          {/* Error messages */}
          <Typography
            data-cy="error-page-message"
            sx={{ marginBottom: "40px", fontSize: "14px", letterSpacing: "-0.02em", width: "100%", maxWidth: "403px" }}
          >
            <>
              {description
                ? description
                : "We couldn't find what you were looking for. Please check the URL and try again."}
            </>
          </Typography>
        </Box>
      </div>
    </main>
  )
}
