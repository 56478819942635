import InputAdornment from "@mui/material/InputAdornment"
import TfTextField from "./TfTextField"

export type ImageDim = "height" | "width"

interface ImageDimInputProps {
  type: ImageDim
  value: string | undefined
  setValue: (t: ImageDim, val: number) => void
}

const ImageDimInput = ({ value, setValue, type }: ImageDimInputProps) => {
  return (
    <TfTextField
      size="small"
      placeholder={type}
      className="img-dims-input"
      inputProps={{
        type: "number",
      }}
      value={value}
      InputProps={{
        endAdornment: <InputAdornment position="end">px</InputAdornment>,
      }}
      sx={{ paddingRight: "8px" }}
      onChange={(e) => setValue(type, Number(e.target.value))}
    />
  )
}

export default ImageDimInput
