import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

const primary = { main: "#1e78ff", dark: "#196eee" }
const secondary = { main: "#0F0E0D" }
const elements = { main: "#fd243e", dark: "#e35353" }
const social = { google: "#1976D2", microsoft: "#00A4DF" }
const white = "#ffffff"
const darkShade1 = "#2d3a48"
const success = "#47be8a"
const error = "#E56B6B"
const warning = "#f6a609"
const grey = "#F1F1F2"
const darkRed = "#FD243E"
const textPrimary = "#111013"
const textSecondary = "#A09FA1"

const GlobalTheme = createTheme({
  palette: {
    primary: {
      main: primary.main,
      dark: primary.dark,
    },
    secondary: {
      main: secondary.main,
    },
    elements: {
      main: elements.main,
    },
    success: {
      main: success,
    },
    error: {
      main: error,
    },
    warning: {
      main: warning,
    },
    social: {
      google: social.google,
      microsoft: social.microsoft,
    },
    grey: {
      main: grey,
    },
    darkRed: {
      main: darkRed,
    },
    white: white,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },

  typography: {
    fontFamily: ["SuisseIntl"].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: textPrimary,
    caption: {
      fontWeight: 300,
      opacity: 0.8,
      color: textSecondary,
    },
    body1: {
      fontWeight: 400,
      color: textPrimary,
      fontSize: "14px",
      lineHeight: "21px",
    },
    body2: {
      fontWeight: 400,
      color: textPrimary,
      fontSize: "14px",
      lineHeight: "21px",
    },
    allVariants: {
      color: textPrimary,
    },
    button: {
      fontWeight: 400,
      fontSize: "14px",
      textTransform: "capitalize",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 400,
          fontSize: "14px",
          boxShadow: "none",
          "&.MuiButton-containedPrimary": {
            backgroundColor: primary.main,
            "&:hover": {
              backgroundColor: primary.dark,
              boxShadow: "none",
            },
          },
          "&.MuiButton-outlinedPrimary": {
            backgroundColor: white,
            borderColor: primary.main,
            "&:hover": {
              color: primary.main,
              backgroundColor: white,
              boxShadow: "none",
            },
          },
          "&.MuiButton-containedInfo": {
            backgroundColor: "#F3F3F1",
            borderColor: "#F3F3F1",
            color: "#636078",
            "&:hover": {
              color: "#00022D",
              backgroundColor: "#F3F3F1",
              boxShadow: "none",
            },
          },
          "&.MuiButton-outlinedInfo": {
            backgroundColor: "#FAFAFA",
            borderColor: "#E2E2E2",
            color: "#00022D",
            "&:hover": {
              color: "#00022D",
              backgroundColor: "#F3F3F1",
              boxShadow: "none",
            },
          },
          "&.MuiButton-containedSecondary": {
            backgroundColor: elements.main,
            "&:hover": {
              backgroundColor: elements.dark,
              boxShadow: "none",
            },
          },
          "&.google": {
            backgroundColor: white,
            color: social.google,
            borderColor: social.google,
            "&:hover": {
              color: social.google,
              borderColor: social.google,
              backgroundColor: white,
              boxShadow: "none",
            },
          },
          "&.microsoft": {
            backgroundColor: white,
            color: social.microsoft,
            borderColor: social.microsoft,
            "&:hover": {
              color: social.microsoft,
              borderColor: social.microsoft,
              backgroundColor: white,
              boxShadow: "none",
            },
          },
          "&.MuiButton-sizeLarge": {
            height: "54px",
            borderRadius: 8,
          },
          "&.MuiButton-sizeSmall": {
            minWidth: "88px",
            height: "40px",
            borderRadius: 8,
            fontSize: "14px",
            lineHeight: "19px",
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 400,
          fontSize: "14px",
          color: "secondary.main",
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "12px",
          // color: "#FFFFFF",
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "0px",
          width: "28px",
          height: "16px",
          borderRadius: "16px",
        }),
        switchBase: ({ ownerState }) => ({
          padding: "2px 14px 2px 2px",
          "&.Mui-checked": {
            color: "#FFFFFF",
            backgroundColor: "#111013",
            padding: "2px 2px 2px 14px",
            borderRadius: "16px",
            transform: "translate(0.5px)",
          },
           "&.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "#111013",
              opacity: 1,
           },
        }),
        thumb: ({ ownerState }) => ({
          height: "12px",
          width: "12px",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#FFFFFF",
          borderRadius: "16px",
        }),
        track: ({ ownerState }) => ({
          backgroundColor: "#EDEDF2",
          opacity: "1",
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // color: "#FFFFFF",
          backgroundColor: "#FFFFFF",
          bottom: 0
        }
      }
    },

    MuiTabPanel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          letterSpacing: 0,
          padding: "60px 0",
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: "#111013",
          backgroundColor: "#FAFAFA",
          borderColor: "#E2E2E2",
          fontSize: 14,
          fontWeight: 600,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)",
          height: "100%",
        }),
      },
      MuiCardContent: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            backgroundColor: red,
          }),
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({
          color: "#111013CC"
        })
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: () => ({
          borderRadius: 2,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: () => ({
          padding: 20,
        }),
      },
    },
    MuiLinearProgress:{
      styleOverrides: {
        root: () => ({
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: darkRed,
        },
        }),
      }, 
    }
  },
})

export default GlobalTheme
