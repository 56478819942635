import mixpanel from "mixpanel-browser"
import { getEnvFromHost } from "./utils"
import { BETA_URL, ENVS, PROD_URL } from "./config"

export const shouldTrackEvents = () => {
  const host = window.location.origin
  if (host.startsWith(BETA_URL) || host.startsWith(PROD_URL)) {
    return true
  }
  return false
}

const getMixPanelInit = () => {
  if (getEnvFromHost(window.location.toString()) === ENVS.PROD) {
    return "956ccc6b7b43cecd5df146a20bd57c54"
  } else {
    return "4b387d7a61e682bcd1df7f539ba9d72e"
  }
}

// Initialize mixpanel statically
if (shouldTrackEvents()) {
  mixpanel.init(getMixPanelInit())
}

export const tracker = {
  identify: (id: any) => {
    if (shouldTrackEvents()) mixpanel.identify(id)
  },
  alias: (id: any) => {
    if (shouldTrackEvents()) mixpanel.alias(id)
  },
  track: (name: any, props?: any) => {
    if (shouldTrackEvents()) mixpanel.track(name, props)
  },
  people: {
    set: (props: any) => {
      if (shouldTrackEvents()) mixpanel.people.set(props)
    },
    set_once: (eventName: any, time: any) => {
      if (shouldTrackEvents()) mixpanel.people.set_once(eventName, time)
    },
    increment: (counter: any) => {
      if (shouldTrackEvents()) mixpanel.people.increment(counter)
    },
  },
  register: (props: any) => {
    if (shouldTrackEvents()) mixpanel.register(props)
  },
  time_event: (eventName: any) => {
    if (shouldTrackEvents()) mixpanel.time_event(eventName)
  },
}

export const initTracker = (props?: any) => {
  if (shouldTrackEvents()) {
    tracker.register({
      env: getEnvFromHost(window.location.toString()),
      sourceApp: "salesforce_contentblock",
      ...props,
    })
  }
}

export enum Events {
  LOAD_BLOCK = "SfdcCbLoadBlock",
  LAYOUT_PAGE_LOAD = "SfdcCbLayoutPageLoad",
  LAYOUT_APPLY = "SfdcCbLayoutApply",
  DOCUMENT_READ_ONLY = "SfdcCbDocumentReadOnly",
  IMAGE_CDN_LOAD_TIME = "SfdcImageCdnLoadTime",
}
